<template>
  <div class="bg-white p-h-30 p-t-50 p-b-20 style">
    <div class="flex p-v-15 p-h-10">
      <div class="style-config flex-1">
        <div class="m-b-15">
          <span>全局默认文字样式</span>
          <el-button class="style-tool-button--text m-l-10" icon="el-icon-plus" @click="handleFont">外挂字体</el-button>
        </div>
        <div class="style-config-container clearfix">
          <BaseStyle :styleData="styleData" :extra_font_list="styleData.extra_font_list" v-model="value" :clearable="false" :exclude="['text_align']" />
        </div>
        <div class="m-t-20">
          <el-tooltip class="item" content="CMYK预设值：此处可提前预设好全书的CMYK颜色，配书的时候实现颜色的直接套用" placement="top-start" effect="light" :open-delay="300">
            <i class="el-icon-question"></i>
          </el-tooltip>
          CMYK 全局配置:
          <div class="cmyk-config">
            <div class="style-tool-cmyk-box">
              <span class="base-label p-r-20">CMYK:</span>
              <span><el-input :value="color.cmyk[0]" @input="inputCmyk($event, 0)" placeholder="C"></el-input></span>
              <span><el-input :value="color.cmyk[1]" @input="inputCmyk($event, 1)" placeholder="M"></el-input></span>
              <span><el-input :value="color.cmyk[2]" @input="inputCmyk($event, 2)" placeholder="Y"></el-input></span>
              <span><el-input :value="color.cmyk[3]" @input="inputCmyk($event, 3)" placeholder="K"></el-input></span>
              <el-button size="mini" type="primary" class="style-tool-button--text m-l-10" @click="addGlobalColor">新增</el-button>
            </div>
          </div>
          <div class="m-t-10">
            <el-tag size="mini" closable v-for="(item, index) in globalColor" :key="index" effect="dark" :style="{ backgroundColor: `rgb(${item.rgb.join(',')})`}" @close="removeGlobalColor(item)" class="m-r-10 m-b-10">CMYK ({{item.cmyk.join(', ')}}) </el-tag>
          </div>
        </div>
      </div>
      <div class="style-example m-l-60 p-h-10 p-v-20">
        <div class="bg-white p-h-15 p-t-50" :style="exampleStyle">
          这是一段示例文字，主要显示全局文字样式的效果。包含字体、字号、行间距等等
        </div>
      </div>
    </div>
    <el-dialog
      title="外挂字体配置"
      :visible.sync="fontDialog"
      width="800px">
      <el-form :inline="true" :model="form" label-width="120px" :rules="rules" ref="form" size="mini">
        <el-form-item label="外挂字体类型：">
          <el-select v-model="form.type">
            <el-option label="中文字体" value="cn" />
            <el-option label="英文字体" value="en" />
          </el-select>
        </el-form-item>
        <el-form-item label="外挂字体名称：" prop="font_name">
          <el-input v-model="form.font_name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addPluginFont">添 加</el-button>
        </el-form-item>
      </el-form>
      <div class="plugin-list">
        <div class="plugin-label">已配置列表：</div>
        <div class="list">
          <el-tag
            class="m-l-10 m-b-10"
            size="medium"
            effect="plain"
            type='primary'
            v-for="item in styleData.extra_font_list"
            :key="item.font_name"
            closable
            @close="delPluginFont(item)">
            {{item.font_name}}
            </el-tag>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fontSize, fontFamilyCn, fontFamilyEn, fontMargin, pluginFontList, replaceFontSizeMap } from '@/assets/styleTool/map';

import BaseStyle from '../components/BaseStyle';
import _ from 'lodash';
export default {
  name: 'BookFontStyle',
  components: {
    BaseStyle
  },
  props: {
    styleData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      color: {
        cmyk: [],
        rgb: []
      },
      fontSize,
      pluginFontList,
      fontMargin,
      fontDialog: false,
      actFont: 'cn',
      lineHeight: Array.from({ length: 20 }, (_item, index) => {
        return index * 0.5 + 0.5;
      }),
      form: {
        font_name: '',
        type: 'cn'
      },
      rules: {
        font_name: [{ validator: this.validator, trigger: 'change' }]
      },
      textAlign: [
        { key: '居左', value: 'left' },
        { key: '居中', value: 'center' },
        { key: '居右', value: 'right' }
      ],
      value: {
        font_color: {}
      }
    };
  },
  computed: {
    globalColor () {
      const { book_default_style: { global_color } } = this.styleData;
      if (!global_color) return [];
      return global_color;
    },
    fontFamilyCn () {
      const { extra_font_list } = this.styleData;
      const temp = {};
      extra_font_list.filter(item => item.type === 'cn').forEach(item => temp[item.font_name] = item.font_name);
      return { ...fontFamilyCn, ...temp };
    },
    fontFamilyEn () {
      const { extra_font_list } = this.styleData;
      const temp = {};
      extra_font_list.filter(item => item.type === 'en').forEach(item => temp[item.font_name] = item.font_name);
      return { ...fontFamilyEn, ...temp };
    },
    pluginFont: {
      get () {
        const { actFont } = this;
        const list = this.styleData.extra_font_list.filter(item => item.type === actFont).map(item => item.font_name);
        return list;
      },
      set (val) {
        const { extra_font_list } = this.styleData;
        const tempList = extra_font_list.filter(item => item.type !== this.actFont);
        this.styleData.extra_font_list = [...tempList, ...val.map(item => ({ font_name: item, type: this.actFont }))];
      }
    },
    placeholderFontSize () {
      return this.fontSize.find(item => item.value === this.placeholder.font_size)?.key || '10.5';
    },
    exampleStyle () {
      const { font_color, text_align, line_spacing, font_size, font_family_en, font_family_cn, font_family_num } = this.value;
      return {
        color: this.getColor(font_color.rgb),
        textAlign: text_align,
        lineHeight: line_spacing + 1,
        fontSize: replaceFontSizeMap[font_size] || font_size,
        fontFamily: `${font_family_en}, ${font_family_num}, ${font_family_cn}`
      };
    }
  },
  created () {
    this.value = this.styleData.components[0].default_style;
    this.value.font_color.cmyk = this.value.font_color.cmyk || [];
  },
  methods: {
    removeGlobalColor (val) {
      console.log(val);
      const { book_default_style: { global_color } } = this.styleData;
      const index = global_color.indexOf(val);
      if (index >= 0) global_color.splice(index, 1);
    },
    addGlobalColor () {
      const { book_default_style: { global_color } } = this.styleData;
      const { cmyk } = this.color;
      if (cmyk.length !== 4 || cmyk.some(item => isNaN(parseFloat(item)))) {
        this.$message.warning('输入CMYK不符合要求');
        return;
      }
      global_color.push(this.color);
      this.color = {
        cmyk: [],
        rgb: []
      };
    },
    inputCmyk (val, position) {
      val = +val.replace(/[^0-9]/g, '');
      val = val < 0 ? 0
        : val > 100 ? 100 : val;
      const { cmyk } = this.color;
      cmyk.splice(position, 1, val);
      if (!cmyk || cmyk.length !== 4) return;
      this.color.rgb = this.cmykToRGB(cmyk);
    },
    validator (rule, value, callback) {
      if (!value) {
        return callback(new Error('名字不能为空!'));
      }
      const repeatName = this.styleData.extra_font_list.find(item => item.font_name === value);
      if (repeatName) return callback(new Error('名字已重复!'));
      callback();
    },
    addPluginFont () {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        const { extra_font_list } = this.styleData;
        extra_font_list.push(_.clone(this.form));
        this.$refs.form.resetFields();
      });
    },
    delPluginFont (item) {
      const { extra_font_list } = this.styleData;
      const index = extra_font_list.findIndex(item1 => item.font_name === item1.font_name);
      if (index >= 0) extra_font_list.splice(index, 1);
    },
    handleFont () {
      this.fontDialog = true;
    },
    cmykToRGB ([c, m, y, k]) {
      const r = Math.round(255 * (1 - c / 100) * (1 - k / 100));
      const g = Math.round(255 * (1 - m / 100) * (1 - k / 100));
      const b = Math.round(255 * (1 - y / 100) * (1 - k / 100));
      return [r, g, b];
    },
    getColor (rgb) {
      if (!rgb || !rgb.length) {
        return 'rgb(0, 0, 0)';
      }
      return `rgb(${rgb.join(',')})`;
    },
  }
};
</script>

<style lang="scss" scoped>
.style {
  .cmyk-config {
    background-color: #f0fafe;
    padding: 10px;
    margin-top: 10px;
  }
  .plugin-list {
    display: flex;
    .plugin-label {
      flex: none;
      width: 120px;
      text-align: right;
      line-height: 28px;
      padding-right: 12px;
    }
    .list {
      flex: auto;
      display: flex;
      flex-wrap: wrap;
    }
  }
  &-config {
    &-container {
      padding: 20px 15px;
      background: #f0fafe;

      .item {
        display: flex;
        align-items: center;
        float: left;
        width: 50%;
        margin-bottom: 25px;
        padding-right: 20px;
        /deep/ .el-input {
          width: 120px;
          &__inner {
            color: #2b333f;
          }
        }
      }

      .color {
        width: 120px;
        height: 40px;
        padding: 8px 15px;
        background: #fff;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        &-bar {
          margin-right: 10px;
          height: 100%;
        }
      }
    }
  }
  &-example {
    width: 280px;
    height: 400px;
    background: #d7d7d7;
    > div {
      height: 100%;
      overflow: auto;
    }
  }
  .label {
    flex-shrink: 0;
    text-align: right;
    width: 75px;
    margin-right: 15px;
  }
}
</style>
